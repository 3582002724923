<template>
	<div class="bg-white" style="min-height: 87vh">
		<div class="padding-lg flex justify-content-between aligin-items-center">
			<router-link :to="{name:'addOrder'}">
				<el-button type="primary">创建订单</el-button>
			</router-link>
			<div class="flex aligin-items-center" v-if="radio1 == '订单列表'">
				<div>
					<el-input placeholder="搜索" v-model="customerName" class="input-with-select" style="width: 400px">
						<el-select v-model="customerSelect" slot="prepend" style="width: 110px" @change="customerSelectChange">
							<el-option label="学员姓名" value="1"></el-option>
							<el-option label="学员电话" value="2"></el-option>
						</el-select>
						<el-button slot="append" icon="el-icon-search" @click="btnClick"></el-button>
					</el-input>
				</div>
			</div>
		</div>

		<div style="padding:10px 20px">
			<as-list :ajaxMethods="{ list: 'getOrderList' }" ref="asList" :listQueryProp="listQuery">
				<el-table-column prop="id" label="订单号"></el-table-column>
				<el-table-column prop="student_name" label="学员姓名"></el-table-column>
				<!-- <el-table-column prop="phone" label="联系方式"></el-table-column> -->
				<el-table-column prop="order_title" label="订单名称"></el-table-column>
				<el-table-column prop="order_body" label="交易内容"></el-table-column>
				<el-table-column prop="order_price" label="实收"></el-table-column>
				<el-table-column prop="arrears_money" label="欠费"></el-table-column>
				<el-table-column prop="order_type_name" label="订单类型"></el-table-column>
				<el-table-column prop="status_name" label="订单状态"></el-table-column>
				<el-table-column prop="order_tags" label="订单标签"></el-table-column>
				<el-table-column prop="handle_school_name" label="经办校区"></el-table-column>
				<el-table-column  label="经办日期">
					<template slot-scope="scope">
						<span v-if="scope.row.handle_date" class="cursor">
							{{ scope.row.handle_date | formatTimes}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="sale_customer_name" label="销售员"></el-table-column>
				<el-table-column prop="handle_user_name" label="经办人"></el-table-column>
				<!-- <el-table-column label="操作">
					<template slot-scope="scope">
						<el-dropdown trigger="click">
							<label>
								操作
								<i class="el-icon-arrow-down el-icon-caret-bottom"></i>
							</label>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item>查看</el-dropdown-item>
								<el-dropdown-item>删除</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</template>
				</el-table-column> -->
			</as-list>
		</div>

		
		</div>
	</div>
</template>

<script>
	import {
		formatTime
	} from "@/utils/util.js";
	export default {
		data() {
			return {
				radio1: "订单列表",
				customerName: "",
				customerSelect: "",
				num:'0',
				listQuery:{
					student_name:'' ,
					student_phone:'',
					PageIndex: 1,
					PageSize: 15,
					Sort: [{
						"Field": "id",
						"Type": 1
					}]
				},
				options: [{
						value: "选项1",
						label: "黄金糕",
					},
					{
						value: "选项2",
						label: "双皮奶",
					},
					{
						value: "选项3",
						label: "蚵仔煎",
					},
					{
						value: "选项4",
						label: "龙须面",
					},
					{
						value: "选项5",
						label: "北京烤鸭",
					},
				],
				value: "",
				value1: "",
				isShow: false,
				tableData: [{
						date: "2016-05-02",
						name: "王小虎",
						address: "1518 弄",
					},
					{
						date: "2016-05-04",
						name: "王小虎",
						address: "1517 弄",
					},
					{
						date: "2016-05-01",
						name: "王小虎",
						address: "1519 弄",
					},
					{
						date: "2016-05-03",
						name: "王小虎",
						address: "1516 弄",
					},
				],
				form: {
					name: "",
					region: "",
					date1: "",
					date2: "",
					delivery: false,
					type: [],
					resource: "",
					desc: "",
					price: "0.00",
				},
			};
		},
		created() {

		},
		filters:{
			formatTimes(val){
				let time=''
				if(val){
					time=formatTime(val,'yyyy-MM-dd')
				}
				return time
			}
		},
		mounted() {},
		methods: {
			chooseContect() {
				this.$Dialog.ChooseContact().then(res => {})
			},
			onSubmit() {},
			customerSelectChange(val){
				this.num=val
			},
			btnClick(){
				if(this.num=='1'){
					this.listQuery.student_name =this.customerName
				}else if(this.num=="2"){
					this.listQuery.student_phone  =this.customerName
				}
			}
		}
	};
</script>

<style scoped>
	>>>.el-form {
		min-width: 500px;
	}
</style>
